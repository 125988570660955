import React, { useState } from "react";
import { Link } from "gatsby";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import TopLogo from "../SVG/toplogo";
import "./navbar.scss";

function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleScroll = () => {
    setNavbarOpen(false);
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <>
      <nav className="w-full relative flex flex-wrap justify-between px-2 py-3 text-gray-light lg:h-20 z-50 nav-fix nav-bg">
        <div className="w-full lg:w-11/12  container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <TopLogo />
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FaBars size="1.5em" />
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow lg:items-center absolute top-0 right-0 lg:fixed lg:w-full lg:h-20 bg-red-dark lg:bg-transparent menu" +
              (navbarOpen ? " menu-active" : "")
            }
          >
            <ul className="pl-5 lg:pl-0 flex flex-col lg:flex-row list-none lg:ml-auto">
              <button
                className="mb-10 ml-auto cursor-pointer text-xl leading-none px-9 py-4 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={handleScroll}
              >
                <ImCross />
              </button>
              <li className="my-3 lg:my-1 flex items-center">
                <Link
                  to="/"
                  className="px-3 text-xl lg:text-md leading-snug hover:text-white"
                  onClick={handleScroll}
                >
                  <span className="ml-2">Home</span>
                </Link>
              </li>
              <li className="my-3 lg:my-1 flex items-center">
                <Link
                  to="/aboutus"
                  className="px-3 text-xl lg:text-md leading-snug hover:text-white"
                  onClick={handleScroll}
                >
                  <span className="ml-2">About</span>
                </Link>
              </li>
              <li className="my-3 lg:my-1 flex items-center">
                <Link
                  to="/services"
                  className="px-3 text-xl lg:text-md leading-snug hover:text-white"
                  onClick={handleScroll}
                >
                  <span className="ml-2">Services</span>
                </Link>
              </li>
              <li className="my-3 lg:my-1 flex items-center">
                <Link
                  to="/team"
                  className="px-3 text-xl lg:text-md leading-snug hover:text-white"
                  onClick={handleScroll}
                >
                  <span className="ml-2">Our Team</span>
                </Link>
              </li>
              <li className="my-3 lg:my-1 flex items-center">
                <Link
                  to="/contactus"
                  className="px-3 text-xl lg:text-md leading-snug hover:text-white"
                  onClick={handleScroll}
                >
                  <span className="ml-2">Contact us</span>
                </Link>
              </li>
              <li className="my-3 lg:my-1 flex items-center">
                <Link
                  to="/blog"
                  className="px-3 text-xl lg:text-md leading-snug hover:text-white"
                  onClick={handleScroll}
                >
                  <span className="ml-2">Blog</span>
                </Link>
              </li>
              <li className="my-3 lg:my-1 flex items-center">
                <Link
                  to="/contactus"
                  className="px-3 text-xl lg:text-md leading-snug hover:text-white"
                  onClick={handleScroll}
                >
                  <button className="ml-2 bg-red max-md:bg-red-dark max-md:border max-md:border-white text-white p-2 hover:shadow-md rounded">
                    Get a Quote
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="w-full relative flex flex-wrap justify-between px-2 py-3 text-gray-light h-20 z-0 mb-6"></div>
    </>
  );
}

export default Navbar;
