import React from "react";
import toplogo from "../../images/toplogo.svg";
import { Link } from "gatsby";

function TopLogo() {
  return (
    <Link to="/">
      <img src={toplogo} alt="Dusk Logo" width={65} height={61.7} />
    </Link>
  );
}

export default TopLogo;